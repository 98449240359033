.img-a {
    height: 100px;
    width: 100px;
}

.row-h {
    display: flex;
    flex-direction: row;
}

.col-h {
    display: flex;
    flex-direction: column;
}

.content-h {
    width: auto;
}

@media screen and (max-width: 600px) and (min-width: 450px) {

    .content-h {
        width: 100%;
    }

}

@media screen and (min-width: 600px) {

    .content-h {
        width: 100%;
    }

    article {
        border-radius: 5px;
        border-color: #000000;
        border-style: solid;
        border-width: 2px;
        width: 600px;
        margin: 5px;
        
    }
}