h1 {
    size: 40px;
}

.row {
    display: flex;
    flex-direction: row;
    background-color: #FFFFFF;
}

.col {
    display: flex;
    flex-direction: column;
}

.menu {
    padding: 0px 5px 0px 0px;
    background-color: #FFFFFF;
}

.content {
    width: 450px;
    background-color: #FFFFFF;
    border: black;
    border-width: 2px;
    border-style: solid;
    border-radius: 5px;

}

header {
    display: flex;
    background-color: #FFFFFF;
    width: 450px;

}

@media screen and (max-width: 600px) and (min-width: 450px) {
    .content {
        width: auto;
    }

    header {
        width: 100%;
    }

}

@media screen and (min-width: 600px) {
    .content {
        width: auto;
    }

    header {
        width: 100%;
    }

}


